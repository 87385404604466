import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layouts/en"
import Seo from "../../components/seo"
import SimplePage from "../../templates/simple-page"
import Features from "../../components/features"
import Link from "../../components/link"
import BackLink from "../../components/back-link"
import Download from "../../components/download"

const DeliveryProgramPage = () => {
  const { fileNode, image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { glob: "delivery-program-wire/*.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        fileNode: sanityDownload(
          file: {en: {fileName: {eq: "Finkernagel delivery programme wire"}}}
        ) {
          id
          title {
            en
          }
          file {
            en {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Delivery programme wire" />
      <SimplePage
        title="Delivery programme wire"
        prefix={<BackLink to="/wire" />}
      >
        <Download extension={fileNode.file.en.file.asset.extension}
          fileName={`/static/${fileNode.file.en.fileName}.${fileNode.file.en.file.asset.extension}`} 
          key={fileNode.id} 
          title="Download delivery programme"
          size={fileNode.file.en.file.asset.size}/>

        <h2 className="text-black">Delivery forms</h2>
        <Features
          items={[
            "Wire formers / spiders from Ø 1.00 mm – 10.00 mm up to 1,000 kg",
            "Coils from Ø 1.00 mm – 33.00 mm up to 3,000 kg",
            "Layered coils from Ø 2.00 mm – 16.00 mm up to 3,000 kg",
          ]}
        />

        <h2 className="text-black">Materials</h2>
        <Features
          items={[
            "Cold heading wire for bolts, nuts and cold extrusion parts according to DIN EN 10263, parts 1 to 4",
            "Cr, CrB, CrMo or CrNiMo alloyed materials",
            "Heat-resistant cold heading wire, e.g. 21CrMoV5-7",
            "Shaped wire (hexagonal, square, drawing profiles)",
            "Ball and roller bearing steels according to DIN EN ISO 683-17, e.g. D85, 100Cr6",
            "Tool and spring steels, e.g. 50CrV4, 61CrSiV5",
            "Unalloyed steels for quenching and tempering, e.g. C60E, C85",
            "Custom materials",
          ]}
        />

        <h2 className="text-black">Surfaces</h2>
        <Features
          items={[
            "Zinc phosphated",
            "Zinc phosphated and reactive soaped",
            "Zinc phosphate-free",
            "Bright",
            "Soaped",
            "Polymer coated",
          ]}
        />

        <p>
          <Link to="/contact#sales-wire" className="accessory">
            Contact us
          </Link>
        </p>

        {image && (
          <figure className="-mb-2 mt-12">
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
          </figure>
        )}
      </SimplePage>
    </Layout>
  )
}

export default DeliveryProgramPage
